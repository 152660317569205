var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"profile_webcam_display conditional_form_element hidden\">\n            <div class=\"profile_webcam_camera\">\n                <div class=\"profile_webcam_overlay\">\n                    <div class='webcam-loader tws-large-loader'></div>\n                </div>\n                <div class=\"webcam_flash\" id=\""
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"webcam_id") : depth0), depth0))
    + "\"></div>\n            </div>\n\n            <div class='text-right'>\n                <button type=\"button\" class=\"tws-btn profile_webcam_cancel_button\">\n                    "
    + alias1(__default(require("../helpers/loc.js")).call(alias2,{"name":"loc","hash":{"key":"cancel","value":"Cancel"},"data":data,"loc":{"start":{"line":26,"column":20},"end":{"line":26,"column":55}}}))
    + "\n                </button>\n\n                <button type=\"button\" class=\"tws-btn--primary profile_webcam_take_button\">\n                    "
    + alias1(__default(require("../helpers/loc.js")).call(alias2,{"name":"loc","hash":{"key":"PhotoHelpers-takePhoto","value":"Take Picture"},"data":data,"loc":{"start":{"line":30,"column":20},"end":{"line":30,"column":77}}}))
    + "\n                </button>\n            </div>\n        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "tws-flex-column";
},"5":function(container,depth0,helpers,partials,data) {
    return "tws-flex-row";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"formalname") : depth0), depth0))
    + "</div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <h3 class='mb-4'>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"photoUploadTitle") : depth0), depth0))
    + "</h3>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "                <button type=\"button\" class=\"tws-btn--primary profile_picture_webcam_button mb-2\">\n                    "
    + container.escapeExpression(__default(require("../helpers/loc.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"loc","hash":{"key":"PhotoHelpers-useCamera","value":"Use my camera"},"data":data,"loc":{"start":{"line":51,"column":20},"end":{"line":51,"column":78}}}))
    + "\n                </button>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "                <br>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<div class=\"profile_photo_wrap\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"supportsWebCam") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":4},"end":{"line":34,"column":11}}})) != null ? stack1 : "")
    + "\n    <div class='"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"renderAsColumn") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":36,"column":16},"end":{"line":36,"column":80}}})) != null ? stack1 : "")
    + " profile_picture_display'>\n        <div class='tws-flex-cell-fixed mr-4 mb-2'>\n"
    + ((stack1 = container.invokePartial(require("../common/_avatar.hbs"),depth0,{"name":"common/_avatar","hash":{"avatar":(depth0 != null ? lookupProperty(depth0,"avatar") : depth0)},"data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"userDetails") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":12},"end":{"line":42,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n\n        <div class='tws-flex-cell'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"photoUploadTitle") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":12},"end":{"line":48,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"supportsWebCam") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":12},"end":{"line":53,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"renderAsColumn") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":12},"end":{"line":57,"column":19}}})) != null ? stack1 : "")
    + "\n            <b class=\"profile_picture_upload_button tws-btn relative\">\n                "
    + alias2(__default(require("../helpers/loc.js")).call(alias1,{"name":"loc","hash":{"key":"PhotoHelpers-Upload","value":"Upload a photo"},"data":data,"loc":{"start":{"line":60,"column":16},"end":{"line":60,"column":72}}}))
    + "\n            </b>\n\n            <div class=\"mt-4 profile_photo_actions_embedded conditional_form_element\">\n                <button type=\"button\" class=\"tws-btn profile_picture_embed_camera_button\">\n                    "
    + alias2(__default(require("../helpers/loc.js")).call(alias1,{"name":"loc","hash":{"key":"PhotoHelpers-useCamera","value":"Use my camera"},"data":data,"loc":{"start":{"line":65,"column":20},"end":{"line":65,"column":78}}}))
    + "\n                </button>\n            </div>\n        </div>\n    </div>\n\n\n</div>";
},"usePartial":true,"useData":true});